.home-container {
  padding: 0 10%;
  margin-top: 15%;
}

.home-container h1 {
  font-size: 3rem;
  color: var(--color-primary);
  margin-bottom: 0;
}

.home-container h2 {
  color: var(--color-primary);
  font-size: 1.35rem;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.home-container p {
  color: #62697B;
  margin-bottom: 1rem;
}

.custom-form-item {
  position: relative;
  margin-bottom: 30px;
}

.custom-form-item input,
.custom-form-item .ant-input,
.custom-form-item .ant-select-selector .ant-select-selection-item {
  font-size: 16px;
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: transparent;
  height: 48px;
  box-sizing: border-box;
  vertical-align: middle;
}

.ant-picker {
  background-color: transparent;
  border-color: var(--border-color);
  height: 48px;
}

.ant-picker-small {
  height: 48px !important;
}

.ant-picker :hover,
.ant-picker-small :hover,
.ant-picker-input input :hover {
  border-color: var(--border-color) !important;
}

.floating-label {
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 16px;
  color: #999;
  transition: all 0.3s ease;
  pointer-events: none;
}

/* Ajuste cuando el campo no tiene selección (estado inicial con placeholder) */
.item-flag .floating-label {
  left: 40px;  /* Mueve el label hacia la derecha */
}

.custom-form-item.focused .floating-label,
.custom-form-item input:focus + .floating-label,
.custom-form-item input:not(:placeholder-shown) + .floating-label,
.custom-form-item .ant-select-focused + .floating-label,
.custom-form-item .ant-picker-input input:focus + .floating-label,
.custom-form-item .ant-picker-input input:not(:empty) + .floating-label,
.custom-form-item .home-custom-flag-select:not(:empty) + .floating-label
.custom-form-item .custom-select.ant-select-focused + .floating-label,
.custom-form-item .ant-select-selector .ant-select-selection-item:not(:empty) + .floating-label,
.custom-form-item .gender-select + .floating-label{
  transform: translateY(-22px);
  font-size: 12px;
  color: #000;
  background-color: var(--background-color);
  padding: 0 5px;
}

/* Cuando el campo tiene selección, ajusta la posición del label */
.item-flag.focused .floating-label {
  left: 12px;  /* Mantén la posición original si ya está seleccionado */
}

.ant-picker-focused {
  background-color: transparent !important;
}

.custom-form-item .ant-picker {
  width: 100%;
  height: 48px;  /* Control de altura */
  border-color: #8A91A4;  /* Color de borde */
  font-size: 16px;  /* Tamaño de fuente */
  background-color: transparent;  /* Fondo transparente */
}

.custom-form-item .ant-picker-input input {
  height: 48px;  /* Control de altura */
  font-size: 16px;  /* Tamaño de fuente */
  color: #000;  /* Color del texto */
  border: 0px !important;
  padding: 0 5px;
}

/* Estilo para el calendario desplegable */
.ant-picker-dropdown {
  border-radius: 12px;  /* Bordes redondeados */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* Sombra */
}

.ant-picker-cell-selected {
  background-color: #240070 !important;  /* Color de la fecha seleccionada */
  color: #fff !important;  /* Color del texto en la fecha seleccionada */
  border-radius: 8px !important;
}

.ant-picker-cell-inner:hover {
  background-color: #EDEAFF !important;  /* Color de fondo al hacer hover */
  color: #240070 !important;  /* Color del texto en hover */
}

.ant-picker-header-view {
  color: #240070;  /* Color primario */
  font-weight: bold;  /* Negrita */
  font-size: 18px;  /* Tamaño de fuente */
  display: flex;
  justify-content: center;
}

.custom-datepicker-dropdown {
  width: inherit !important;
  margin: 0 auto !important;
}

.custom-datepicker-dropdown .ant-picker-dropdown {
  width: auto !important;  /* Ajuste automático */
  max-width: none !important;
  min-width: 100% !important;
}

.custom-datepicker-dropdown .ant-picker-panel-container,
.custom-datepicker-dropdown .ant-picker-panel-layout div {
  width: 100% !important;
}

.custom-datepicker-dropdown .ant-picker-date-panel {
  width: auto !important;
  margin: 0 auto !important;
  max-width: 400px !important; /* Define un límite de ancho para evitar que sea demasiado ancho */
  left: 50% !important; /* Coloca el dropdown en el centro horizontal */
  min-width: 100px !important;
}

.form-row {
  display: flex;
  gap: 16px;
}

.form-row .custom-form-item {
  flex: 1;
}

.home-container-form {
  width: 100%;
  margin: 0 auto;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.custom-checkbox label {
  margin-left: 8px;
  font-size: 14px;
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.ant-checkbox-wrapper .ant-checkbox {
  margin-right: 8px;
}

.ant-picker-dropdown,
.ant-select-dropdown {
  transition: none !important;
}

.home-custom-flag-select {
  width: 100%;
  border: none !important;
  display: flex;
  padding-bottom: 0px !important;
}

.home-custom-flag-select-container {
  height: 48px;
  border-radius: 8px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  width: 100%;
  position: relative;
}

.home-custom-flag-select button {
  height: 48px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.home-custom-flag-select .flag-select__option {
  padding-left: 5px !important; /* Ajustar el padding interno para mayor alineación */
  padding-right: 5px !important;
}

.flag-select__wrapper {
  border: none !important;
}

.home-custom-flag-select::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22><path d=%22M5 14H19V5H5V21%22 stroke=%22%234F5667%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.home-custom-flag-select-button::after {
  display: none !important;
}

.home-custom-flag-select-button {
  position: relative;
}

/* Añadir el SVG como ícono del select */
[class^="ReactFlagsSelect-module_selectValue"]:after {
  content: '';
  width: 16px;  /* Ajusta el tamaño según sea necesario */
  height: 16px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z%22 fill=%22%234F5667%22/%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}

button[aria-expanded="true"] [class^="ReactFlagsSelect-module_selectValue"]::after {
  transform: translateY(-50%) rotate(180deg); /* Rotar 180 grados cuando está abierto */
}

[class^="ReactFlagsSelect-module_selectOptions"] {
  top: 42px;
  border-radius: 8px !important;
  box-shadow: 0px 21px 8px rgba(17, 17, 27, 0.01), 0px 12px 7px rgba(17, 17, 27, 0.05), 0px 5px 5px rgba(17, 17, 27, 0.09), 0px 1px 3px rgba(17, 17, 27, 0.1);
}

[class^="ReactFlagsSelect-module_selectBtn"] {
  padding-left: 40px !important; /* Aumentar el padding del contenedor del botón */
  text-align: left; /* Asegura que el texto esté alineado a la izquierda */
  display: flex;
  align-items: center; /* Asegura que el texto esté alineado verticalmente con el ícono */
}

/* Reducir el padding y text-indent cuando hay un país seleccionado */
.country-selected [class^="ReactFlagsSelect-module_selectBtn"] {
  padding-left: 10px !important;
  text-indent: 0px; /* Elimina el espacio adicional cuando un país está seleccionado */
}

.country-selected.home-custom-flag-select .ReactFlagsSelect-module_selectBtn {
  padding-left: 10px !important; /* Reduce el espacio cuando se selecciona un país */
}

.country-selected.home-custom-flag-select [class^="ReactFlagsSelect-module_selectValue"] {
  white-space: normal !important;  /* Evita que el texto se desborde */
}

.country-selected.home-custom-flag-select::before {
  display: none !important;
}

.home-custom-flag-select .ReactFlagsSelect-module_selectBtn {
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Estilo del icono SVG por defecto */
.custom-form-item .home-custom-flag-select .flag-select__icon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.home-custom-select {
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 8px;
}
.home-custom-select .ant-select-selector {
  border: 0 !important;
  width: 100%;
  padding: 0px !important;
}

.home-custom-select .ant-select-selector .ant-select-selection-item {
  border: 0 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.home-custom-select .ant-select-dropdown {
  border-radius: 8px;
  padding: 8px 0;
  width: 100%;
}

.home-custom-select .ant-select-item-option:hover {
  background-color: red;
  color: #240070;
}

.home-custom-select .anticon {
  display: none;
}

/* Añadir el nuevo SVG con el ícono personalizado */
.home-custom-select .ant-select-arrow {
  content: '';
  position: absolute;
  right: 12px;
  top: 60%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z%22 fill=%22%234F5667%22/%3E%3C/svg%3E') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s ease;
}

/* Rotar el ícono cuando el select está abierto */
.home-custom-select.ant-select-open .ant-select-arrow {
  transform: translateY(-50%) rotate(180deg) !important; /* Rotar el ícono */
}

.ant-checkbox-inner {
  border-radius: 2px !important;
  border: 1px solid #8A91A4 !important;
  width: 20px !important;
  height: 20px !important;
}

.home-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  width: 40%;
  background-color: var(--color-primary);
  color: #fff;
  height: 45px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button .button-active:hover {
  background-color: #3700B3;
}

.dark-modal .ant-modal-content {
  background-color: #1e1e1e;
  color: #fff !important;
}

.ant-modal .ant-modal-body,
.ant-modal .ant-modal-body * {
  font-size: inherit !important;
}

.dark-modal .ant-modal-header .ant-modal-title,
.dark-modal .ant-modal-body,
.dark-modal .ant-modal-footer {
  background-color: #1e1e1e;
  color: #fff !important;
}

.dark-modal .ant-modal-body, 
.dark-modal .ant-modal-body * {
  color: #fff !important;
  font-size: inherit !important;
}

.footer-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

@media only screen and (max-width: 768px) {
  .home-container {
    padding: 0 20px;
    margin: 0;
  }

  .home-container h1 {
    font-size: 39px;
  }

  .home-container h2 {
    font-size: 16px;
  }

  .home-container p {
    font-size: 14px;
  }

  /* Ajusta el formulario a una columna */
  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .custom-form-item {
    width: 100%;
    margin-bottom: 30px;
  }

  .home-container-form {
    width: 100%;
  }

  .custom-datepicker-dropdown .ant-picker-date-panel {
    max-width: 350px !important; /* Define un límite de ancho para evitar que sea demasiado ancho */
    left: 0 !important; /* Coloca el dropdown en el centro horizontal */
    min-width: 100px !important;
  }

  /* Ajustar los checkbox */
  .ant-checkbox-wrapper {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .home-button-container {
    margin-top: 0px;
  }

  .submit-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 24px;
    margin-top: 20px;
  }

  .ant-tabs-tab-btn {
    display: block;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .footer-button-group {
    flex-direction: column; /* cambia la disposición a vertical */
    align-items: stretch;   /* los botones ocupan todo el ancho */
    justify-content: center !important; /* centrar los botones */
  }

  /* Ajuste adicional si quieres separar cada botón */
  .footer-button-group button {
    margin-bottom: 8px;
    font-size: 0.85rem;
    padding: 6px 10px;
  }

}



