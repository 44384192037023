/* sidebar.less */
.custom-sidebar {
  background-color: transparent;  /* Fondo transparente */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 80px);  /* Altura completa menos el tamaño del header */
  top: 80px;  /* Posición del sidebar justo debajo del header */
  //position: fixed;  /* Fijado en el viewport */
  overflow: visible;
  z-index: 1;
}

.sidebar-background {
  background-image: url('../../assets/img/girlshop.webp');  /* Imagen de fondo */
  background-size: cover;  /* Ajustar la imagen para cubrir todo */
  background-position: center;
  position: relative;
  height: 100%;  /* Ocupa toda la altura del sidebar */
  width: 100%;  /* Ocupa todo el ancho del sidebar */
}

.sidebar-top-image {
  margin-top: -75px;
}

.sidebar-bottom-image {
  position: absolute;
  bottom: 0;
  left: 45%;
}

/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  /* .custom-sidebar {
    width: 100vw;
    height: auto;
    top: 0;
  } */

  .sidebar-top-image {
    top: 20px;  /* Ajusta el espacio superior */
    max-width: 90%;
  }

  .sidebar-bottom-image {
    bottom: 20px;  /* Ajusta el espacio inferior */
    max-width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .custom-sidebar {
    display: none;
  }
}
