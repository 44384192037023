.mobile-header-container {
  position: relative;
  left: 50%; /* Empuja el contenedor a la mitad del viewport */
  right: 50%; /* Empuja el contenedor hacia la derecha */
  margin-left: -50vw; /* Mueve hacia la izquierda para ocupar el 100% del ancho */
  margin-right: -50vw; /* Mueve hacia la derecha para ocupar el 100% del ancho */
  width: 100vw; /* Ocupa todo el ancho del viewport */
  height: 300px;  /* Ajusta la altura según sea necesario */
  overflow: hidden;
  z-index: 1; /* Asegúrate de que se renderice debajo del contenido */
  margin-top: 0; /* Elimina cualquier espacio entre el header y el contenedor */
  padding-top: 0; /* Elimina el padding en la parte superior */
}

.header-image-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/girl_horizontal_v2.webp') !important;  /* Ruta de la imagen de la chica */
  background-size: cover;  /* Asegura que la imagen cubra todo el div */
  background-position: center;  /* Centra la imagen */
  background-repeat: no-repeat;  /* No se repite la imagen */
}

.header-image-svg {
  position: absolute;
  top: 175px;
  left: 0;
  width: 70px;  /* Ajusta el tamaño del SVG */
  z-index: 10;
}

/* Solo en versión móvil */
@media (max-width: 768px) {
  .mobile-header-contaner {
    display: block;  /* Mostrar solo en dispositivos móviles */
  }
}

@media (min-width: 769px) {
  .mobile-header-container {
    display: none;  /* Ocultar en pantallas más grandes */
  }

  .header-image-svg {
    display: none;  /* Ocultar en pantallas más grandes */
  }
}
