.h1-tyc {
    margin-top: 10px;
    font-size: 2rem !important;
}

.div-tyc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.div-tyc p {
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.5;
    margin-top: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.75rem;
}

.button-tyc {
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.div-body-tyc {
    height: 40vh;
    overflow-y: auto;
}

.dark-modal {
  background-color: #1e1e1e !important;
}

.dark-modal h1,
.dark-modal h4,
.dark-modal h5,
.dark-modal b,
.dark-modal p {
  color: #fff;
}

@media screen and (max-width: 768px) {
    .div-tyc p {
        font-size: 0.6rem;
        padding-left: 2px;
        padding-right: 2px;
    }
}