.custom_header {
  background-color: var(--color-primary);
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}

.custom_header_logo
.img_logo {
  height: 50px;
  display: flex;
}

.custom_menu_container {
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 50px !important;
}

.custom_header_nav {
  display: flex;
  align-items: center;
  height: 40px;
  background: transparent;
}

.custom_header_nav_item {
  //margin-left: 20px;
  background: transparent !important;
}

.custom_header_nav_item:hover {
  background: transparent !important;
}

.custom_select_dropdown
.ant-select-selector {
  padding: 10px 15px;
  font-size: 16px;
}

.custom_select_dropdown
.ant-select-item-option {
  color: black !important;
}

.custom_select_dropdown
.ant-select-item-option
.ant-select-item-option-selected {
  background-color: var(----background-color) !important;
  font-weight: bold !important;
}

.custom_select_dropdown
.ant-select-item-option
.ant-select-item-option-active {
  background-color: white !important;
}

.ant-select-selection-item {
  color: white !important;
}

.ant-menu-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-menu-item-active :hover {
  border: none !important;
}

.custom-arrow {
  color: white; /* Cambia el color de la flecha a blanco */
  transition: transform 0.3s ease; /* Agrega una transición suave para la rotación */
}

.custom-arrow.rotate {
  transform: rotate(180deg); /* Rota la flecha cuando el menú está abierto */
}
.ant-select-selector {
  border: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
  padding: 0 9px !important;
}

.ant-menu-item {
  padding: 0px 0px 0px 10px !important;
}

@media only screen and (max-width: 900px) {
  .custom_header {
    left: 0;
    top: 0;
    z-index: 999;
  }
}

@media only screen and (max-width: 768px) {
  .custom_header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 25px;
    height: 64px;
  }

  .custom_header_logo
  .img_logo {
    height: 35px;
    display: flex;
  }
}
