.layout-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //z-index: 999;
}

.layout-body {
  /* display: flex;
  flex-direction: column;
  flex-grow: 1;
  top: 0;
  height: 100vh; */
  flex: 1;
  display: flex;
}

.layout-content {
  /* flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: block; */
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layout-content .ant-layout-content {
  flex: 1;
}

/* .layout-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 10vh;
  margin-top: auto;
} */

.layout-footer {
  background-color: #f0f2f5;
  padding: 24px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .layout-main {
    display: flex;
    flex-direction: column;
  }

  .layout-body {
    flex-direction: column;
    height: auto;  /* Permite que el contenido crezca dinámicamente según el tamaño del viewport */
  }

  .layout-content {
    padding: 0;
    margin-top: 0px;
  }

  .layout-footer {
    height: auto;  /* Ajusta la altura del footer en la vista móvil */
  }
}
