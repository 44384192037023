.ant-layout-footer {
  background-color: var(--background-color) !important;
  padding: 15px !important;
}

.custom-footer {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-footer p {
  font-size: 12px;
}

.custom-footer-img {
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 768px) {

  .custom-footer p {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
  }
}
