/* discount.css */

.discountContent {
  display: flex;
  justify-content: center;
  margin-top: 230px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  min-height: 55vh;
  background-color: #f8f8f8 !important;
  z-index: 8888;
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: auto;
}

.discountBody {
  width: 85%;
  padding: 10px;
}

.discountContainCards {
  display: grid;
  gap: 10px;
  justify-content: center;
}

.categoryPills {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  padding: 10px 0;
  z-index: 1000;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.categoryPills::-webkit-scrollbar {
  display: none;
}

.categoryPills {
  -ms-overflow-style: none; /* Oculta la barra de desplazamiento en IE y Edge */
  scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
}

.pill {
  font-family: "Poppins", sans-serif;
  padding: 8px 16px;
  border: 1px solid #4f5667;
  border-radius: 20px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 14px;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.pill:hover {
  background-color: #8200ff;
  color: #fff;
}

.pill.active {
  border: 2px solid #251660;
}

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-message h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.error-message p {
  font-size: 1.2rem;
}

.error-message button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--color-secondary);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: var(--color-secondary-dark);
}

@media (min-width: 1200px) {
  .discountContainCards {
    grid-template-columns: repeat(4, 275px);
  }
}

@media (max-width: 1199px) and (min-width: 600px) {
  .discountContainCards {
    grid-template-columns: repeat(3, 275px);
  }
}

@media (max-width: 900px) {
  .discountContainCards {
    grid-template-columns: repeat(2, 163px);
  }
  .pagination {
    display: none !important;
  }
  .pill {
    padding: 6px 12px;
    font-size: 12px;
  }
}

.layout-content {
  padding: 0px !important;
}

/* Estilos para la paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  margin: 0 10px;
}

.discountModalContent {
  margin: 0 auto;
}

.discountModalHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.discountModalHeader img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #aab2c5;
  margin-right: 20px;
}

.discountModalHeader h2 {
  margin: 0;
  padding: 0;
  font-size: 30px;
}

.discountModalLocation,
.discountModalInfo {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}

.discountModalLocation img,
.discountModalInfo img {
  margin-right: 10px;
}

.discountModalInfo {
  background-color: #e2f6ff;
  border-radius: 16px;
  border: 1px solid #0067a7;
  padding: 10px;
}

.no-discount {
  position: absolute;
  width: 85%;
  padding: 20px;
  text-align: center;
  border: 1px solid gray;
  border-radius: 20px;
}

@media only screen and (max-width: 900px) {
  .categoryPills {
    top: 82px;
  }
}

@media only screen and (max-width: 768px) {
  .discountContent {
    margin-top: 329px;
  }

  .discountBody {
    width: 100%;
    padding: 0px;
  }

  .categoryPills {
    top: 7.5%;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 10px;
    gap: 8px;
  }

  .pill {
    padding: 6px 12px;
    font-size: 12px;
  }
}
