/* success.css */
.success-title h1 {
  font-size: 31px;
  margin: 0px;
  line-height: 1.3;
}

.success-title p,
.important-contain p,
.step .step-container p {
  margin: 0px;
  font-size: 16px;
}

.step .step-number-container p {
  font-size: 16px;
  text-align: left;
}

.success-container {
  background-color: var(--color-primary);
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px;
  color: white;
}

.success-content {
  margin-top: 160px;
  height: auto;
  background-color: var(--background-color);
  padding: 20px 80px 30px 80px;
  border-radius: 12px;
  max-width: 800px;
  text-align: center;
  color: #000; /* Texto negro en el contenido */
  z-index: 3;
}

.step-container-line {
  background-image: url('../../assets/img/Rectangle 5663.svg');
  blackground-size: contain;
  background-repeat: no-repeat;
  background-position: center 50%;
}

.success-background-svg-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 190px;
  height: 250px;
  background-image: url('../../assets//img/CencoDecoration_up_success.svg'); /* Imagen SVG superior */
  background-repeat: no-repeat;
}

.success-background-svg-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 230px;
  height: 330px;
  background-image: url('../../assets/img/CencoDecoration_dow_succes.svg'); /* Imagen SVG inferior */
  background-repeat: no-repeat;
}

.layout-body.success-page {
  background-color: var(--color-primary); /* Cambia el fondo de toda la página a azul */
}

.success-footer-container {
  z-index: 99;
  margin-top: 15px;
  bottom: 0;
}

.success-page .ant-layout {
  padding: 0px;
}

.important-message {
  margin-top: 10px;
  display: flex;
  background-color: #E2F6FF;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #0067A7;
}

.important-message img {
  padding: 10px 10px 10px 0px;
}

.important-contain p {
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.step {
  background-color: var(--background-color);
  border: 1px solid black;
  border-radius: 16px;
  padding: 5px 10px 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.step .step-number-container {
  display: flex;
  justify-content: flex-start;
  align-items: center
}


.step .step-number {
  border: 2px solid black;
  border-radius: 50%;
  padding: 15px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  font-size: 16px;
}

.step .step-number strong {
  margin-top: 3px;
  padding: 0px;
}

.step .step-container {
  display: flex;
}

.step .step-container p {
  margin: 0px;
  padding: 2px;
  text-align: left;
}

.step .step-container img {
  margin-right: 6px;
}

@media only screen and (max-width: 768px) {
  .success-container {
    padding: 15px;
    height: auto;
  }

  .success-content {
    margin-top: 60px;
    padding: 40px 20px 40px 20px;
    height: auto;
  }

  .step-container-line {
    background-size: center 43%;
  }
}

@media only screen and (max-width: 555px) {
  .step-container-line {
    background-position: center 43%;
  }
}
