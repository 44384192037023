/* discountCard.css */

.discountCard {
    border: 1px solid #AAB2C5;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    width: 275px;
    height: 210px;
    text-align: center;
    box-sizing: border-box;
}

.discountCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.discountCardImage {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.discountCardImage img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.discountCardText {
    width: 100%;
    margin-top: 10px;
}

.discountCardText h3 {
    margin: 0;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.discountOffer {
    color: #8200FF;
    font-size: 18px;
    margin: 5px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Media query para pantallas menores a 900px */
@media (max-width: 900px) {
    .discountCard {
        width: 163px;
        height: 176px;
    }

    .discountCardImage {
        width: 80px;
        height: 80px;
    }

    .discountCardImage img {
        max-width: 100%;
        max-height: 100%;
    }

    .discountCardText h3 {
        font-size: 16px;
    }

    .discountOffer {
        font-size: 14px;
    }
}