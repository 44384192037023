:root {
  --background-color: #F8F8F8;
  --border-color: #8A91A4;
  --text-color-: #101826;
  --text-color-light: #F5F1F8;
  --color-primary: #251660;
  --color-disabled: #AAB2C5;
}

html {
  background-color: var(--background-color);
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif !important; /* Aplica Poppins a todo el documento */
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--background-color);
}

html body {
  height: 100%;
  padding: 0;
  margin: 0;
}
