.discount_custom_header_main {
  background-color: var(--color-primary);
  display: block;
  position: fixed;
  height: 300px;
  z-index: 4;
  width: 100%;
  padding: 15px 50px;
}

.discount_custom_header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discount_custom_header_logo .img_logo {
  height: 50px;
  display: flex;
}

.discount_custom_menu_container {
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 50px !important;
  z-index: 3;
}

.discount_custom_header_nav {
  display: flex;
  align-items: center;
  height: 40px;
  background: transparent;
}

.discount_custom_header_nav_item {
  background: transparent !important;
}

.discount_custom_header_body {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  margin-top: -30px;
  color: white;
  text-align: left;
}

.discount_custom_header_body h1 {
  font-size: 3rem;
  padding: 0px;
  margin-bottom: 0px;
}

.discount_custom_header_table {
  border-collapse: separate;
  td {
    padding-right: 20px;
  }
  z-index: 6;
}

.discount_custo_header_back {
  position: absolute;
  margin-top: -70px;
  top: 0;
  right: 0;
  width: 50%;
  z-index: -1;
  height: auto;
}

.discount_custom_header_guys {
  display: flex;
  justify-content: center;
  align-items: center;
}

.discount_custom_header_guys img {
  width: 95%;
  margin-top: -40px;
  z-index:5;
}

.discount_custom_select_dropdown .ant-select-selector {
  padding: 10px 15px;
  font-size: 16px;
}

.discount_custom_select_dropdown .ant-select-item-option {
  color: black !important;
}

.discount_custom_select_dropdown
  .ant-select-item-option
  .ant-select-item-option-selected {
  background-color: var(----background-color) !important;
  font-weight: bold !important;
}

.discount_custom_select_dropdown
  .ant-select-item-option
  .ant-select-item-option-active {
  background-color: white !important;
}

.ant-select-selection-item {
  color: white !important;
}

.ant-menu-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px 0px 0px 10px !important;
}

.ant-menu-item-active :hover {
  border: none !important;
}

.discount_custom-arrow {
  color: white; /* Cambia el color de la flecha a blanco */
  transition: transform 0.3s ease; /* Agrega una transición suave para la rotación */
}

.discount_custom-arrow.rotate {
  transform: rotate(180deg); /* Rota la flecha cuando el menú está abierto */
}
.ant-select-selector {
  border: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
  padding: 0 9px !important;
}

@media only screen and (max-width: 480px) {
  .discount_custom_header_top {
    margin-top: -4px;
  }

  .discount_custom_header {
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 25px;
    height: 64px;
  }
  
  .discount_custom_header_main {
    width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
    height: 400px;
  }

  .discount_custom_header_logo .img_logo {
    height: 35px;
    display: flex;
  }

  .discount_custom_header_body {
    width: 100%;
    padding: 10px;
  }

  .discount_custom_header_body h1 {
    font-size: 2rem;
    margin-top: 40px;
  }

  .discount_custom_header_guys img {
    position: absolute;
    bottom: 0;
    width: 350px !important;
    left: 30%;
    /* width: 80%; */
    margin-top: 20px;
  }

  .discount_custom_header_table {
    width: 100%;
  }
  .discount_custom_header_table thead {
    display: none; /* Ocultar los encabezados de la tabla */
  }

  .discount_custom_header_table tbody,
  .discount_custom_header_table tr,
  .discount_custom_header_table td {
    display: block;
    width: 100%;
  }

  .discount_custom_header_table tr {
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  .discount_custom_header_table td {
    text-align: left;
    padding: 5px 0;
    position: relative;
  }
  /* Mostrar etiquetas antes del contenido de cada celda */
  .discount_custom_header_table td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: white; /* Asegúrate de que el texto sea legible */
    color: #f0f0f0;
  }

  .discount_custo_header_back {
    top: 0;
    margin: 0px;
    width: 100%;
    left: 30%;
  }
}
